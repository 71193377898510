/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hospital': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M507.514 249.429L289.849 35.499c-18.665-18.347-49.034-18.346-67.698-.001L4.486 249.429c-5.909 5.808-5.991 15.305-.184 21.213 5.807 5.907 15.304 5.991 21.212.184l16.939-16.649v170.797c0 35.999 29.288 65.287 65.287 65.287h296.52c35.999 0 65.287-29.288 65.287-65.287V254.177l16.939 16.649a14.954 14.954 0 0010.513 4.302c3.882 0 7.763-1.498 10.699-4.485 5.807-5.909 5.725-15.406-.184-21.214zm-67.968 175.545c0 19.457-15.83 35.287-35.287 35.287H107.74c-19.457 0-35.287-15.83-35.287-35.287V224.692L243.18 56.894a18.067 18.067 0 015.995-3.909c6.003-2.388 13.053-1.493 18.27 2.687.474.38.933.787 1.375 1.222l170.727 167.797v200.283z"/><path pid="1" d="M210.929 196.98v48.271h-48.274c-8.284 0-15 6.716-15 15v59.866c0 8.284 6.716 15 15 15h48.274v48.27c0 8.284 6.716 15 15 15h59.866c8.284 0 15-6.716 15-15v-48.27h48.266c8.284 0 15-6.716 15-15v-59.866c0-8.284-6.716-15-15-15h-48.266V196.98c0-8.284-6.716-15-15-15h-59.866c-8.284 0-15 6.716-15 15zm30 63.271V211.98h29.866v48.271c0 8.284 6.716 15 15 15h48.266v29.866h-48.266c-8.284 0-15 6.716-15 15v48.27h-29.866v-48.27c0-8.284-6.716-15-15-15h-48.274v-29.866h48.274c8.284 0 15-6.716 15-15z"/>'
  }
})
