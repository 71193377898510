/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'document': {
    width: 16,
    height: 16,
    viewBox: '0 0 477.867 477.867',
    data: '<path pid="0" d="M324.267 119.467c-9.426 0-17.067-7.641-17.067-17.067V0H102.4C74.123 0 51.2 22.923 51.2 51.2v375.467c0 28.277 22.923 51.2 51.2 51.2h273.067c28.277 0 51.2-22.923 51.2-51.2v-307.2h-102.4z"/><path pid="1" d="M341.333 10.001v75.332h75.333z"/>'
  }
})
