/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'building(1)': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M482.284 482h-39.573V15c0-8.284-6.716-15-15-15H84.288c-8.284 0-15 6.716-15 15v467H29.716c-8.284 0-15 6.716-15 15s6.716 15 15 15h452.569c8.284 0 15-6.716 15-15s-6.716-15-15.001-15zm-277.415 0V379.739H307.13V482zm132.261 0V364.739c0-8.284-6.716-15-15-15H189.869c-8.284 0-15 6.716-15 15V482H99.288V30h313.423v452z"/><path pid="1" d="M223.748 66.309H150.24c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15V81.309c0-8.284-6.716-15-15-15zm-15 73.508H165.24V96.309h43.508zM361.76 66.309h-73.508c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15V81.309c0-8.284-6.716-15-15-15zm-15 73.508h-43.508V96.309h43.508zM223.748 205.954H150.24c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15v-73.508c0-8.284-6.716-15-15-15zm-15 73.508H165.24v-43.508h43.508zM361.76 205.954h-73.508c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15v-73.508c0-8.284-6.716-15-15-15zm-15 73.508h-43.508v-43.508h43.508z"/>'
  }
})
