/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'paper': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M507.786 64.62c-.304-.286-60.445-57.641-60.445-57.641-2.299-2.206-4.677-4.486-9.117-4.486h-242.12c-7.072 0-12.826 5.753-12.826 12.825v39.383L97.943 69.329c-6.84 1.2-11.44 7.746-10.255 14.579l4.331 25.252c-27.737 9.334-56.214 18.956-83.705 28.831-6.496 2.375-9.905 9.598-7.587 16.133L55.412 306.14a7.503 7.503 0 007.058 4.964 7.5 7.5 0 007.057-10.041L15.651 151.295c25.9-9.273 52.722-18.349 78.935-27.172l25.771 150.245L150.011 447.4c1.071 6.108 6.44 10.454 12.5 10.454.686 0 1.382-.056 2.08-.171l80.316-13.783 62.76-10.758-94.391 33.927-74.435 26.763-57.808-160.789a7.5 7.5 0 10-14.115 5.075l58.628 163.074c1.875 5.128 6.733 8.316 11.868 8.316 1.419 0 2.86-.244 4.264-.757l76.671-27.566 174.094-62.574 33.259-5.701h73.471c7.072 0 12.826-5.766 12.826-12.854V73.071c.001-4.489-2.435-6.779-4.213-8.451zm-19.871 1.776h-37.53l-.93.004c-1.797.012-6.004.043-7.071-1.017-.246-.245-.534-1.063-.534-2.582l-.087-40.415zM497 397.908H198.278V251.741a7.5 7.5 0 00-15 0v148.313c0 7.087 5.754 12.854 12.826 12.854h140.812l-94.545 16.206-77.982 13.383-29.248-170.665-32.269-188.13 80.405-13.783v147.022a7.5 7.5 0 0015 0V17.492h228.475l.098 45.326c0 5.494 1.671 9.938 4.966 13.21 5.063 5.027 12.22 5.377 16.663 5.377.382 0 .744-.003 1.083-.005L497 81.397z"/><path pid="1" d="M234.43 118.949a7.5 7.5 0 007.5 7.5h214.436a7.5 7.5 0 000-15H241.93a7.5 7.5 0 00-7.5 7.5zM456.366 164.731H241.93a7.5 7.5 0 000 15h214.436a7.5 7.5 0 000-15zM456.366 218.013H241.93a7.5 7.5 0 000 15h214.436a7.5 7.5 0 000-15zM456.366 271.295H241.93a7.5 7.5 0 000 15h214.436a7.5 7.5 0 000-15zM456.366 324.578H241.93a7.5 7.5 0 000 15h214.436a7.5 7.5 0 000-15z"/>'
  }
})
