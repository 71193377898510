/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'about': {
    width: 64,
    height: 64,
    viewBox: '0 0 16.93 16.93',
    data: '<path pid="0" d="M8.51 0C3.83-.02.02 3.75 0 8.42c-.02 4.68 3.75 8.49 8.42 8.51 4.68.02 8.49-3.75 8.51-8.42C16.96 3.83 13.18.02 8.51 0zm-.12 2.28c.41 0 .75.15 1.03.44.29.28.43.63.43 1.04 0 .41-.14.75-.43 1.04-.28.28-.63.42-1.03.42-.42 0-.76-.14-1.05-.42-.28-.28-.43-.63-.43-1.04 0-.42.15-.76.44-1.04.28-.29.63-.44 1.04-.44zM6 6.07h3.89v7.25h1.17v.93H6v-.93h1.16V7H6z"/>'
  }
})
