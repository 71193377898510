import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { login, logout } from '@/api/users'
import { removeToken } from '@/utils/cookies'
import store from '@/store'
import { Role } from '@/utils/roles'
import router from '@/router'

export interface IUserState {
  connected: boolean
  name: string
  role: Role
}

@Module({ dynamic: true, store, name: 'user', preserveState: localStorage.getItem('vuex') !== null })
class User extends VuexModule implements IUserState {
  public connected = false
  public name = ''
  public role = Role.None

  @Mutation
  private SET_TOKEN(connected: boolean) {
    this.connected = connected
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_ROLES(role: Role) {
    this.role = role
  }

  @Action
  public async Login(userInfo: { username: string, password: string }) {
    let { username, password } = userInfo
    username = username.trim()
    const { data } = await login({ username, password })
  }

  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN(false)
    this.SET_ROLES(Role.None)
  }

  @Action
  public InitToken(accessToken: string) {
    this.SET_TOKEN(true)
    // On parse le payload pour récupérer le nom + les roles de l'utilisateur
    const payload = JSON.parse(window.atob(accessToken.split('.')[1]))
    this.SET_ROLES(payload.role ? parseInt(payload.role) : Role.None)
    this.SET_NAME(payload.email)
  }

  @Action
  public async LogOut() {
    await logout()
    removeToken()
    this.SET_TOKEN(false)
    this.SET_ROLES(Role.None)
    router.push(`/login?redirect=${router.app.$route.fullPath}`)
  }
}

export const UserModule = getModule(User)
