/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'building': {
    width: 31.666,
    height: 31.666,
    viewBox: '0 0 31.666 31.666',
    data: '<path pid="0" d="M11.452 31.666v-5.879h8.763v5.879h6.604V0H4.847v31.666h6.605zm8.763-28.757h3.696v3.696h-3.696V2.909zm0 5.373h3.696v3.697h-3.696V8.282zm0 5.374h3.696v3.695h-3.696v-3.695zm0 5.372h3.696v3.698h-3.696v-3.698zM13.986 2.909h3.697v3.696h-3.697V2.909zm0 5.373h3.697v3.697h-3.697V8.282zm0 5.374h3.697v3.695h-3.697v-3.695zm0 5.372h3.697v3.698h-3.697v-3.698zM7.757 2.909h3.696v3.696H7.757V2.909zm0 5.373h3.696v3.697H7.757V8.282zm0 5.374h3.696v3.695H7.757v-3.695zm0 5.372h3.696v3.698H7.757v-3.698z"/>'
  }
})
