export enum Role {
  None = 0,
  User = 1,
  TEC = 2,
  ARC = 3,
  IRC = 4,
  Investigator = 10,
  Principal_Investigator = 11,
  Coordinator = 12,
  Data_Manager = 20,
  Biostat = 21,
  Promotor = 30,
  Admin = 99
}
