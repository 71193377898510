/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'folder': {
    width: 16,
    height: 16,
    viewBox: '0 0 408 408',
    data: '<path pid="0" d="M332 121.921H184.8l-29.28-34.8a5.001 5.001 0 00-4-1.8H32.76c-18.132.132-32.76 14.868-32.76 33v214.04c.022 18.194 14.766 32.938 32.96 32.96H332c18.194-.022 32.938-14.766 32.96-32.96v-177.48c-.022-18.194-14.766-32.938-32.96-32.96z"/><path pid="1" d="M375.24 79.281H228l-29.28-34.8a5.001 5.001 0 00-4-1.8H76c-16.452.027-30.364 12.181-32.6 28.48h108.28a19.16 19.16 0 0114.72 6.84l25 29.72H332c26.005.044 47.076 21.115 47.12 47.12v167.52c16.488-2.057 28.867-16.064 28.88-32.68v-177.48c-.043-18.101-14.66-32.788-32.76-32.92z"/>'
  }
})
