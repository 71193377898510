import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index.vue'
import { Role } from '@/utils/roles'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
  // mode: 'history',  // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import('@/views/login/index.vue'),
      meta: {
        title: 'Connexion',
        hidden: true
      }
    },
    {
      path: '/404',
      component: () => import('@/views/404.vue'),
      meta: { hidden: true }
    },
    {
      path: '/',
      component: Layout,
      redirect: '/centres',
      children: [
        {
          path: 'centres',
          component: () => import('@/views/dashboard/centres.vue'),
          meta: {
            title: 'Centres',
            icon: 'building',
            authorize: [Role.Admin]
          }
        }
      ]
    },
    {
      path: '/membres',
      component: Layout,
      children: [
        {
          path: '',
          component: () => import('@/views/dashboard/membres.vue'),
          meta: {
            title: 'Membres',
            icon: 'man-user',
            authorize: [Role.Admin]
          }
        },
        {
          path: 'add',
          component: () => import('@/views/membres/add.vue'),
          meta: {
            title: 'Ajout',
            icon: 'dashboard',
            authorize: [Role.Admin],
            hidden: true
          }
        }
      ]
    },
    {
      path: '/compteurs',
      component: Layout,
      children: [
        {
          path: '',
          component: () => import('@/views/dashboard/compteurs.vue'),
          meta: {
            title: 'Fichiers',
            icon: 'folder',
            authorize: [Role.Admin]
          }
        }
      ]
    },
    {
      path: '/a-propos',
      component: Layout,
      children: [
        {
          path: '',
          component: () => import('@/views/dashboard/about.vue'),
          meta: {
            title: 'A propos',
            icon: 'about',
            authorize: [Role.Admin]
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/404',
      meta: {
        hidden: true,
        authorize: []
      }
    }
  ]
})
