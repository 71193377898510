import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { getToken } from '@/utils/cookies'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true,
  timeout: 30000
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    // Some example codes here:
    // code == 20001: token acquis ou rafraichis
    const res = response.data
    if (res.custom_code != null && res.custom_code === 20001) {
      UserModule.InitToken(getToken() || '')
    }
    return response.data
  },
  (error) => {
    // Récupération du custom_code éventuel et traitement spécifique(s)
    if (error.response && error.response.data && error.response.data && error.response.data.custom_code === 40001) {
      MessageBox.alert(
        'Votre session a expirée, veuillez vous reconnecter.',
        'Déconnecté',
        {
          confirmButtonText: 'Se reconnecter',
          type: 'warning'
        }
      ).finally(() => {
        UserModule.LogOut()
      })
    }
    // Traitement par défaut
    else {
      let errorMessage = ''
      if (error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
        errorMessage = error.response.data.data.message
      } else {
        errorMessage = error.message
      }
      Message({
        message: errorMessage,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
