/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M42.5 22h-25a1 1 0 100 2h25a1 1 0 100-2zM17.5 16h10a1 1 0 100-2h-10a1 1 0 100 2zM42.5 30h-25a1 1 0 100 2h25a1 1 0 100-2zM42.5 38h-25a1 1 0 100 2h25a1 1 0 100-2zM42.5 46h-25a1 1 0 100 2h25a1 1 0 100-2z"/><path pid="1" d="M38.914 0H6.5v60h47V14.586L38.914 0zm.586 3.414L50.086 14H39.5V3.414zM8.5 58V2h29v14h14v42h-43z"/>'
  }
})
